.HomeBody {
  top: 12vh;
  width: 100%;
  height: 88vh;
  background-image: url("/src/assets/BG_hackspace_uncrop.jpg");
  background-size: cover;
  background-position: right;
  position: relative;
}
.PIgroup {
  position: absolute;
  bottom: 20vh;
  top: 10vh;
  width: 50%;
}
@keyframes borderIn {
  from {
    outline-offset: 0px;
  }
  to {
    outline-offset: -8px;
  }
}
@keyframes borderOut {
  from {
    outline-offset: -8px;
    outline: 1px solid black;
  }
  to {
    outline-offset: 0px;
  }
}
.PostIt {
  position: absolute;
  text-align: center;
  font-family: "Lora", serif;
  font-style: italic;
  font-size: 1.3em;
  box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.25);
  left: 10%;
  right: 10%;
  height: 30%;
  padding: 0 10vw;
  cursor: pointer;
  animation-name: borderOut;
  animation-duration: 0.1s;
  animation-timing-function: ease-out;
}

.PostIt#About {
  top: 0vh;
  background: rgba(144, 204, 244, 0.8);
}
.PostIt#Projects {
  top: 50%;
  transform: translateY(-50%);
  background: rgba(252, 136, 136, 0.8);
}
.PostIt#Contact {
  bottom: 0vh;
  background: rgba(243, 210, 80, 0.8);
}
.PItitle {
  margin: 0.5em 0 0.5em;
  font-size: 1.5em;
  text-transform: capitalize;
}
.PItitle:after {
  content: "";
  display: block;
  width: 30%;
  height: 4px;
  background-color: black;
  margin: 0.8vh 35% 0;
}

.PostIt:hover {
  outline: 1px solid black;
  animation-name: borderIn;
  animation-duration: 0.1s;
  outline-offset: -8px;
  animation-timing-function: ease-out;
}

@media (orientation: portrait) {
  .PostIt {
    left: 0;
    right: 0;
    height: calc(100% / 3);
    box-shadow: none;
  }
  .PIgroup {
    position: fixed;
    width: 100%;
    bottom: 0vh;
    top: 72vh;
  }
  .PItitle:after {
    display: none;
  }
  .HomeBody {
    height: 60vh;
  }
}
@media (max-width: 900px) {
  .logo {
    width: 70%;
    height: unset;
    top: 25%;
  }

  .PIintro {
    display: none;
  }
}
