.container {
  display: flex;
  align-items: center;
  min-height: 45vh;
}
.CDtext {
  width: 50%;
  text-align: justify;
}
.CDimage {
  width: 50%;
  text-align: center;
}

.ContentTitle.sub:after {
  content: "";
  display: block;
  width: 10%;
  height: 3px;
  background-color: black;
  margin: 0.8vh 45% 0;
}
.CDimage > img {
  width: 70%;
  margin: 0 15%;
}
.Content.limit {
  padding-top: 6vh;
  height: 94vh;
  display: inherit;
}

.CTimage {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
}
.CTimageContainer {
  flex-basis: 40%;
}
.ContentTile {
  margin: 1% 1%;
  background-color: #ececec;
  box-sizing: border-box;
  border-radius: 15px;
  overflow: hidden;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  position: relative;
}
.ContentTile:hover {
  box-shadow: 5px 5px 10px 0px grey;
}
.CTtitle {
  font-size: 1.5em;
}
.CTdesc {
  padding-top: 5%;
  flex-basis: 52.5%;
  padding-left: 5%;
  padding-right: 2.5%;
}
.Content.limit.Center {
  padding-top: 20vh;
}
.CTseeMore {
  position: absolute;
  bottom: 10px;
  right: 15px;
  font-size: 0.8em;
}
.CTsummary {
  margin-bottom: 2em;
}
@media (orientation: landscape) and (max-width: 1600px) {
  .CTsummary {
    display: none;
  }
  .CTseeMore {
    display: none;
  }
}
@media (orientation: landscape) and (max-width: 1200px) {
  .CTtags {
    display: none;
  }
  .CTseeMore {
    display: unset;
  }
}
@media (orientation: portrait) {
  .CTimageContainer {
    flex-basis: 100%;
  }
  .CTdesc {
    flex-basis: 100%;
  }
  .ContentTile {
    margin: 5% 1%;
  }
}
