.Home.Header {
  height: 12vh;
  background-color: #ececec;
}
.Header button {
  font-family: "Lora", serif;
  font-style: italic;
  border: none;
}
.Header button:not([disabled]):after {
  content: "";
  display: block;
  width: 0%;
  height: 2px;
  background-color: black;
  margin: 5px 50% 0;
  animation-name: lineOut;
  animation-duration: 0.1s;
  animation-timing-function: ease-out;
}
.Header button:hover:not([disabled]):after {
  content: "";
  display: block;
  width: 50%;
  height: 2px;
  background-color: black;
  margin: 5px 25% 0;
  animation-name: lineIn;
  animation-duration: 0.1s;
  animation-timing-function: ease-out;
}

.BArrow {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 24px;
  cursor: pointer;
}

.Hamburger {
  display: none;
  z-index: 2;
  position: fixed;
  top: 20px;
  right: 20px;
}

.overlay {
  height: 0%;
  width: 100%;
  position: fixed;
  background-color: #ececec;
  background-image: url("/src/assets/paper.jpg");
  z-index: 1;
  top: 0;
  left: 0;
  overflow-y: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  font-family: "Lora";
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 3em;
  color: black;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

@keyframes lineIn {
  from {
    width: 0%;
    margin: 5px 50% 0;
  }
  to {
    width: 50%;
    margin: 5px 25% 0;
  }
}
@keyframes lineOut {
  from {
    width: 50%;
    margin: 5px 25% 0;
  }
  to {
    width: 0%;
    margin: 5px 50% 0;
  }
}
@media (orientation: portrait) and (max-width: 1500px) {
  .BArrow {
    display: none;
  }
  .ButtonGroup {
    display: none;
  }
  .Hamburger {
    display: unset;
  }
}
