.Header {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
  text-align: right;
}
.logo {
  position: absolute;
  top: 20%;
  height: 60%;
  left: 50%;
  font-size: 2.5em;
  transform: translateX(-50%);
}
.Body {
  background-color: #ececec;
  height: 100vh;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.SideBar {
  height: 100vh;
  flex-basis: 30%;
  background-color: #f78888;
  text-align: center;
  animation: leftRight 0.2s ease-in;
  overflow: hidden;
}
.SideContent {
  padding: 24vh 10%;
}
.SideTitle {
}
.SideText {
  padding: 5vh 0%;
  font-size: 1.25em;
}
.SideFooter {
}
.scrollSnap {
  scroll-snap-type: y mandatory;
}
.ContentHolder {
  margin-top: 6vh;
  height: 94vh;
  overflow-y: scroll;
  flex-basis: 70%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.Content {
  padding: 0 6vh;
  box-sizing: border-box;
  font-family: "Lora", serif;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}
button {
  background-color: transparent;
  padding: 0.75em 1.75em;
  font-family: "Nunito", sans-serif;
  border-color: black;
  font-size: 1.15em;
  border-width: 2.5px;
  cursor: pointer;
  margin: 0.25em;
}
.ContentTitle {
  text-align: center;
  font-size: 3em;
  margin-top: 5vh;
}
.ContentDesc {
  margin-top: 5vh;
}
.text-center {
  text-align: center;
}
.ContentFooter {
  margin-top: 10vh;
}
.ContentHolder::-webkit-scrollbar {
  display: none;
}
@keyframes leftRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
#About {
  background: rgba(144, 204, 244);
}
#Projects {
  background: rgba(252, 136, 136);
}
#Contact {
  background: rgba(243, 210, 80);
}
:focus {
  outline: none;
}

@media (orientation: landscape) and (max-width: 900px) {
  .App {
    font-size: 0.7rem;
  }
}
@media (orientation: portrait) {
  .ContentHolder {
    overflow-y: unset;
    height: 100vh;
    flex: 100%;
  }
  .SideBar {
    flex: 100%;
  }
  .Body {
    overflow-y: scroll;
  }
  ul {
    margin-block-end: 0.5em;
    margin-block-start: 0.5em;
  }
  .Content {
    padding: 0 2vh;
  }
}

@media (orientation: portrait) and (max-width: 500px) {
  .App {
    font-size: 0.7rem;
  }
}
ul {
  padding: 0;
}
ul > li {
  list-style-type: none;
  display: inline-block;
  padding: 0.1em 0.75em;
  margin-right: 0.6em;
  margin-bottom: 0.4em;
  box-sizing: border-box;
  border: 1px solid black;
  border-radius: 10px;
  font-size: 0.8rem;
}
.Row {
  margin-bottom: 3em;
}
.flex-50 {
  flex-basis: 50%;
}
.flex-25 {
  flex-basis: 25%;
}
.flex-75 {
  flex-basis: 75%;
}

.Nodes {
  position: fixed;
  left: 0.5%;
  top: 50%;
  transform: translateY(-50%);
  text-align: left;
  list-style-type: circle;
  list-style-position: inside;
}
.Nodes button {
  font-family: "Lora", serif;
  font-style: italic;
  border: none;
  text-align: left;
  padding: unset;
  line-height: 0.5em;
  display: flex;
  align-items: center;
  color: #828282;
}
.circle {
  height: 1em;
  width: 1em;
  border: 0.2em solid #828282;
  background-image: radial-gradient(
    circle at center,
    #828282 50%,
    transparent 50%
  );
  background-repeat: no-repeat;
  transition: background-size 0.5s, color 0.2s;
  background-size: 0 0;
  background-position: 50% 50%;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  margin-right: 0.5em;
}

.circle:hover {
  background-size: 200% 200%;
  color: #828282;
}
.circle:hover + .text {
  display: unset;
}

.circle:hover:before,
.selected .circle::before {
  width: 100%;
  height: 100%;
}
.selected .circle {
  background-size: 200% 200%;
  color: #828282;
}

.Nodes .text {
  display: none;
  animation: fadeIn 0.2s ease-in;
}
.Nodes .selected .text {
  display: unset;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
