@import "~react-image-gallery/styles/css/image-gallery.css";
.ContentHolder.Project {
  flex-basis: inherit;
}
.ProjectSegment {
  height: 94vh;
}

.ProjectContent {
  font-family: "Lora", serif;
  text-align: center;
}
.ProjectTitle {
  font-size: 3em;
  padding: 5vh 15vw 1em;
}
.ProjectSubTitle {
  font-size: 2em;
  text-align: center;
}
.ProjectSummary {
  text-align: center;
}
.ProjectSummary button {
  margin-top: 1em;
  font-size: 0.9em;
  padding: 0.5em 1.25em;
}
.ProjectMedia {
  width: 50%;
  height: calc(50vw * 0.5625);
  margin: auto;
  margin-bottom: 1em;
  display: block;
}
.ProjectMedia > img {
  width: 100%;
}
.ProjectMedia button {
  margin: unset;
}
.ProjectSegment > .Row {
  padding: 1em 25vw;
}

h3 {
  display: inline-block;
  font-weight: unset;
  font-size: 1em;
  margin-bottom: 0.5em;
  margin: 0.5em 1em 0.5em 0;
}
.ProcessSubTitle.selected {
  font-weight: bold;
  font-size: 1.2em;
}
.centerRIG {
  transform: translateX(55px);
  height: 100%;
}
.ProjectMedia.gallery {
  height: unset;
}
@media (orientation: portrait) {
  .centerRIG {
    transform: translateX(0);
  }
  .ProjectMedia {
    width: 90vw;
    margin-bottom: unset;
    height: calc(90vw * 0.5625);
  }
  .ProjectSegment > .Row {
    padding: 1em 5vw;
    margin: 0;
  }
  .image-gallery-slide-wrapper.left,
  .image-gallery-slide-wrapper.right {
    width: 100%;
    height: unset;
  }
  .image-gallery-thumbnails-wrapper.left,
  .image-gallery-thumbnails-wrapper.right {
    display: none;
  }
  .ProjectTitle {
    padding: 5vh 5vw 1em;
  }
}
@media (max-width: 900px) {
  .ProjectTitle {
    font-size: 2em;
  }
}
@media (max-width: 500px) {
  .ProjectTitle {
    font-size: 1.5em;
    font-weight: 500;
  }
}
